<template>
    <div class="app-inner">
        <div v-if="showSocketConnectionError && authStore.loggedIn" class="socket-connection-error">
            <span class="media-loader connection-loader"></span>
            <span class="text-h3 text-white"> {{ $t('components.connectionError') }}</span>
        </div>
        <router-view />
    </div>
</template>

<script>
import { useAuthStore } from '@/stores/authStore'
import { isMobileOrTablet, updateObjectInArray } from '@/helpers/utils'
import { useChatsStore } from '@/stores/chatsStore'
import { useRoute } from 'vue-router'
import { socket, state } from '@/services/socket'
import { createToaster } from '@meforma/vue-toaster'
import { useChannelsStore } from '@/stores/channelsStore'
import lodash from 'lodash'

export default {
    name: 'IndexComponent',
    inject: ['eventBus'],
    setup() {
        const authStore = useAuthStore()
        const chatsStore = useChatsStore()
        const channelsStore = useChannelsStore()
        const route = useRoute()
        const toaster = createToaster({
            position: 'bottom',
        })

        return { toaster, authStore, chatsStore, route, channelsStore }
    },
    data() {
        return {
            socketState: state,
            showSocketConnectionError: false,
        }
    },
    watch: {
        'authStore.loggedIn': {
            handler(newValue) {
                if (newValue) {
                    if (this.authStore.userInfo?.access_token?.length) {
                        this.subscribeSocket()
                    } else {
                        this.authStore.refreshToken().then(() => {
                            this.$router.push('/')
                            this.subscribeSocket()
                        })
                    }
                } else {
                    this.authStore.handleLogout()
                }
            },
            immediate: true,
        },
        'socketState.connected': {
            async handler(newValue) {
                if (newValue) {
                    this.showSocketConnectionError = false
                } else {
                    await this.checkConnection()
                }
            },
            immediate: true,
        },
    },
    created() {
        this.updateAuthStatus()

        window.addEventListener('storage', this.handleStorageChange)
    },
    beforeUnmount() {
        window.removeEventListener('storage', this.handleStorageChange)
    },
    mounted() {
        this.eventBus.on('navigateToUserProfile', (user) => {
            let link = user?.profile_link

            if (isMobileOrTablet()) {
                if (user?.type == 'instagram') {
                    link = 'instagram://user?username=' + user?.username
                }
            }
            window.open(link, '_blank').focus()
        })
    },
    methods: {
        async checkConnection() {
            await new Promise((resolve) => setTimeout(resolve, 3000))

            if (!this.socketState.connected) {
                this.showSocketConnectionError = true
            }
        },

        updateAuthStatus() {
            const authData = JSON.parse(localStorage.getItem('authStatus'))

            if (authData && typeof authData.isAuthenticated === 'boolean') {
                this.authStore.loggedIn = authData.isAuthenticated
            }
        },

        handleStorageChange(event) {
            if (event.key === 'authStatus') {
                this.updateAuthStatus()
            }
        },

        subscribeSocket() {
            socket.on('conversation_opened_list', (data) => {
                this.chatsStore.openedConversationsList = [...data]
            })
            socket.on('message', this.receiveMessage)
            socket.on('error', this.showError)
            socket.on('connect_error', this.showError)
            socket.on('conversation_close', (data) => {
                const conversationToRemove = this.chatsStore.openedConversationsList.findIndex(
                    (conversation) =>
                        conversation.conversation_id === data.conversation_id && conversation.fullName === data.fullName
                )

                if (conversationToRemove !== -1) {
                    this.chatsStore.openedConversationsList.splice(conversationToRemove, 1)
                }
            })

            socket.on('conversation_open', (data) => {
                this.chatsStore.openedConversationsList.push(data)
            })

            socket.io.on('reconnect', () => {
                this.updateChatsData()
                socket.emit('conversation_opened_list')
            })

            socket.emit('conversation_opened_list')
        },

        updateChatsData() {
            this.authStore.refreshToken().then(() => {
                const { name: routeName, params: routeParams } = this.$route

                if (routeName === 'chatContent' || routeName === 'chats') {
                    this.chatsStore.conversationsPaging = {}
                    this.chatsStore.loadConversations()
                }

                if (routeName === 'chatContent') {
                    this.chatsStore.loadMessages(routeParams.conversation_id)
                }
            })
        },

        showError(error) {
            if (error.code) {
                this.toaster.error(`Websocket error: Code ${error.code} - ${error.message}`)
            } else {
                state.connected = false
                this.toaster.error(`Websocket error: ${error.message}`)
            }
        },

        receiveMessage(data) {
            const newConversationTime = data.messages.created_time
            const { channels, managers, statuses, search } = this.chatsStore.conversationsFilters
            const { channel_id, manager_id, status_id, api_id, user } = data.conversations

            const normalizedManagerId = manager_id || -1
            const normalizedStatusId = status_id || -1

            const conversationMatchesFilters =
                (channels.includes(channel_id) || channels.length === 0) &&
                (managers.includes(normalizedManagerId) || managers.length === 0) &&
                (statuses.includes(normalizedStatusId) || statuses.length === 0) &&
                (!search ||
                    user.name.toLowerCase().includes(search.toLowerCase()) ||
                    user.username.toLowerCase().includes(search.toLowerCase()))

            if (conversationMatchesFilters) {
                try {
                    this.showNotification(data)
                } catch (error) {
                    console.error(error)
                }

                this.chatsStore.conversationsList = updateObjectInArray(
                    this.chatsStore.conversationsList,
                    [{ ...data.conversations, created_at: newConversationTime }],
                    'id'
                )
            }

            if (parseInt(this.route.params.conversation_id, 10) !== api_id) {
                return
            }

            if (data.messages.from === this.chatsStore.senderId && data.messages.type === 'attachment') {
                let elapsedTime = 0
                const interval = setInterval(() => {
                    if (this.chatsStore.sendingMediaFiles.length === 0 || elapsedTime >= 3000) {
                        clearInterval(interval)
                        this.chatsStore.messagesList = updateObjectInArray(
                            this.chatsStore.messagesList,
                            [data.messages],
                            'id'
                        )
                        this.chatsStore.conversationInfo.is_read = false

                        return
                    }
                    elapsedTime += 250
                }, 250)
            } else {
                this.chatsStore.messagesList = updateObjectInArray(this.chatsStore.messagesList, [data.messages], 'id')

                this.chatsStore.conversationInfo.is_read = false
            }
        },

        showNotification(data) {
            if (this.channelsStore.channelsList.length > 0) {
                let webhookChannel = lodash.find(this.channelsStore.channelsList, (channel) => {
                    if (channel.id == data.webhook_data.channel_id) {
                        return channel
                    }
                })

                if (webhookChannel) {
                    Notification.requestPermission().then((perm) => {
                        if (perm === 'granted') {
                            new Notification(`${this.$t('components.newMessage')}: ` + webhookChannel.name, {
                                body: data.messages.message,
                            })
                        }
                    })
                }
            }
        },
    },
}
</script>

<style lang="scss">
@import '@/scss/styles';

.app-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.socket-connection-error {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #dc3645;
    padding: 0.5rem 1rem;
    z-index: 500;
}

.connection-loader {
    position: static;
    display: flex;
    width: 1.4rem;
    height: 1.4rem;
    border: 0.2rem solid rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
    margin-right: 0.5rem;
}

@media screen and (min-width: 576px) {
    .socket-connection-error {
        position: fixed;
        top: 0;
        left: 0;
    }
}
</style>
