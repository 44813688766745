import { defineStore } from 'pinia'
import fetchWrapper from '@/helpers/fetchWrapper'
import { updateObjectInArray, updateObjectInObject } from '@/helpers/utils'
import { socket } from '@/services/socket'
import lodash from 'lodash'

const baseRoute = '/conversations'

export const useChatsStore = defineStore('chatsStore', {
    state: () => ({
        conversationsPaging: {},
        conversationsList: [],
        openedConversationsList: [],
        conversationInfo: {},
        conversationsFilters: {
            channels: [],
            managers: [],
            statuses: [],
            search: null,
        },
        messagesList: [],
        messagesParticipants: {},
        messagesPaging: {},
        senderId: null,
        recipientId: null,
        loadingConversations: false,
        loadingMessages: false,
        loadingStatusChange: [],
        sendingMessage: false,
        sendingMediaFiles: [],
    }),

    actions: {
        async loadConversations() {
            const conversationAfter = this.conversationsPaging?.after

            if (!conversationAfter && this.conversationsList.length && !Object.keys(this.conversationsFilters).length) {
                return
            }

            this.loadingConversations = true

            return await fetchWrapper(`${baseRoute}/get-list`, 'POST', {
                after: conversationAfter,
                filters: this.conversationsFilters,
            })
                .then((response) => {
                    const { response: chatsResponse } = response

                    if (chatsResponse.result?.conversations?.length) {
                        this.conversationsList = updateObjectInArray(
                            this.conversationsList,
                            chatsResponse.result.conversations,
                            'api_id',
                            'end'
                        )
                        this.conversationsPaging = chatsResponse.result.paging
                    }

                    return chatsResponse.result?.conversations
                })
                .finally(() => {
                    this.loadingConversations = false
                })
        },

        changeConversationStatus(conversationId, newStatusId) {
            this.loadingStatusChange.push(conversationId)

            socket.emit('conversations_change_status', {
                conversation_id: conversationId,
                status_id: newStatusId,
            })
        },

        markConversationAsRead() {
            if (!this.conversationInfo.is_read) {
                socket.emit('conversations_mark_as_read', {
                    conversation_id: this.conversationInfo.id,
                })
            }
        },

        syncConversationStatus(conversationId, statusId, currentConversation) {
            if (conversationId === currentConversation) {
                this.conversationInfo.status_id = statusId
            }

            lodash.find(this.conversationsList, (conversation) => {
                this.loadingStatusChange.splice(this.loadingStatusChange.indexOf(conversationId), 1)

                if (conversation.api_id === conversationId) {
                    conversation.status_id = statusId
                }
            })
        },

        async loadMessages(conversationId, loadMore = false) {
            if (loadMore && !this.messagesPaging?.last_message_id) {
                return
            }

            if (!loadMore) {
                this.conversationInfo = {}
                this.messagesPaging = {}
                this.messagesList = []
                this.messagesParticipants = {}
            }

            this.loadingMessages = true
            const lastMessageId = this.messagesPaging?.last_message_id

            return await fetchWrapper(`${baseRoute}/get-messages`, 'POST', {
                conversation_id: conversationId,
                last_message_id: lastMessageId,
            }).then((response) => {
                const { response: messagesResponse } = response

                this.renderMessages(messagesResponse.result)
            })
        },

        renderMessages(data) {
            const { messages, participants, paging, conversation } = data

            const firstTime = !this.messagesList.length

            this.messagesList = this.messagesList.concat(messages)
            this.messagesParticipants = updateObjectInObject(this.messagesParticipants, participants)
            this.messagesPaging = paging
            this.conversationInfo = conversation
            this.checkParticipants(this.messagesParticipants)
            this.loadingMessages = false

            if (firstTime) {
                this.markConversationAsRead()
            }
        },

        async sendMessage(message, type) {
            this.sendingMessage = true

            await fetchWrapper(`${baseRoute}/send-message`, 'POST', {
                conversation_id: this.conversationInfo.id,
                message,
                type,
            })
                .then((response) => {
                    const { response: sendMessageResponse } = response

                    if (!this.messagesList.some((msg) => msg.id === sendMessageResponse.result.id)) {
                        this.messagesList.unshift({
                            id: sendMessageResponse.result.id,
                            message_id: sendMessageResponse.result.message_id,
                            message,
                            type,
                            from: this.senderId,
                            created_time: Date.now(),
                        })
                    }
                })
                .finally(() => {
                    this.sendingMessage = false
                })
        },

        async sendMedia(mediaFile) {
            const fileType = mediaFile.type.split('/')[0]
            const formData = new FormData()
            const headers = {
                'Content-Type': 'multipart/form-data',
            }

            formData.append('conversation_id', this.conversationInfo.id)
            formData.append(fileType, mediaFile)

            const loadedFile = formData.get(fileType)
            const url = URL.createObjectURL(loadedFile)
            const customId = 'id' + Math.random().toString(16).slice(2)

            this.sendingMediaFiles.push(customId)
            this.messagesList.unshift({
                id: customId,
                message_id: customId,
                type: 'attachment',
                from: this.senderId,
                created_time: Date.now(),
                attachments: [{ type: fileType, url }],
                loading: true,
            })

            await fetchWrapper(`${baseRoute}/send-message`, 'POST', formData, '', headers)
                .then((response) => {
                    const { response: sendMessageResponse } = response

                    this.messagesList.map((item) => {
                        if (item.id === customId) {
                            item.id = sendMessageResponse.result.id
                            item.message_id = sendMessageResponse.result.message_id
                            item.loading = false
                        }
                    })
                })
                .catch(() => {
                    this.messagesList.map((item) => {
                        if (item.id === customId) {
                            item.loading = false
                            item.loadingError = true
                        }
                    })
                })
                .finally(() => {
                    const index = this.sendingMediaFiles.indexOf(customId)

                    if (index > -1) {
                        this.sendingMediaFiles.splice(index, 1)
                    }
                })
        },

        async sendAudio(formData) {
            const messageData = formData
            const headers = {
                'Content-Type': 'multipart/form-data',
            }

            messageData.append('conversation_id', this.conversationInfo.id)

            // const loadedFile = formData.get('audio')
            // const url = URL.createObjectURL(loadedFile)
            const customId = 'id' + Math.random().toString(16).slice(2)

            this.sendingMediaFiles.push(customId)
            this.messagesList.unshift({
                id: customId,
                message_id: '',
                type: 'attachment',
                from: this.senderId,
                created_time: Date.now(),
                attachments: [{ type: 'audio', url: '' }],
                loading: true,
            })

            await fetchWrapper(`${baseRoute}/send-message`, 'POST', messageData, '', headers)
                .then((response) => {
                    const { response: sendMessageResponse } = response

                    this.messagesList.map((item) => {
                        if (item.id === customId) {
                            item.id = sendMessageResponse.result.id
                            item.message_id = sendMessageResponse.result.message_id
                            item.loading = false
                        }
                    })
                })
                .catch(() => {
                    this.messagesList.map((item) => {
                        if (item.id === customId) {
                            item.loading = false
                            item.loadingError = true
                        }
                    })
                })
                .finally(() => {
                    const index = this.sendingMediaFiles.indexOf(customId)

                    if (index > -1) {
                        this.sendingMediaFiles.splice(index, 1)
                    }
                })
        },

        onMessageSeen(seenMessage) {
            let newSeenIndex = lodash.findIndex(this.messagesList, { id: seenMessage.id })
            let lastSeenIndex = lodash.findIndex(this.messagesList, { is_seen: true })

            for (let i = newSeenIndex; i < lastSeenIndex; i++) {
                this.messagesList[i].is_seen = true
            }
        },

        onMessageReaction(messageId, reaction) {
            let message = lodash.find(this.messagesList, { id: messageId })

            message.reactions = []

            if (typeof reaction != 'undefined') {
                message.reactions.push(reaction)
            }
        },

        checkParticipants(participants) {
            for (const key in participants) {
                if (participants[key].channel_account) {
                    this.senderId = participants[key].id
                } else {
                    this.recipientId = participants[key].id
                }
            }
        },
    },
})
